<template>
    <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title"><span class="ul_text">CA</span>MPAIGNS</h4> 
      </CRow>
    <CCardBody>
      <div v-if="loading==true">Loading . . .</div>
      <div v-else>
        <CRow>
        <CCol>
          <CRow class="col-md-12">
            <!-- <label>Filter :</label>
            <CInput class="col-md-8"
            @keyup="customFilter"
            v-model="filter"
            type="search"
            placeholder="Type to Search" /> -->
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
      <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect class="pb-2"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
    </CCol>
    </CRow>
        <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      light
      column-filter
    >
    <!-- column-filter -->
    <template #campname="{item}"
            ><td class="camp_td">
              <span v-if="userType =='csp'">{{ item.campname }}</span>
              <span
                v-else
                class="link"
                @click="openModel('CampURL', camp_list[item.campname],item.camp_eid)"
              >
                {{ item.campname }}</span
              >
              <!-- <span class="ml-1" v-if="item.customCR.Enable == true" style="flot:right;">
              <CBadge color="danger" v-if="item.dangCR > item.customCR.dangerous">Danger CR</CBadge>
              <CBadge class="ml-1" color="warning" v-if="item.susCR > item.customCR.suspicious">Suspecius CR</CBadge>
              </span> -->
            </td></template
          >

          <template #offerid="{item}">
            <td class="offer_td">
              <span v-if="userType =='csp'">{{ offer_name[item.offerid] }}</span>
              <span v-else class="link" @click="EditOffer(item)" >
                {{offer_name[item.offerid]}}
                <!-- <span class="link" @click="getofferData(item.offerid)">{{
                  offer_name[item.offerid]
                }}</span> -->
              </span>
            </td>
          </template>

          <template #tsid="{item}">
            <td class="ts_td">
              <span v-if="userType =='csp'">{{ traffic_source_name[item.tsid] }}</span>
              <span class="link" v-else @click="EditTrafficSource(item)">{{ traffic_source_name[item.tsid] }}</span>
            </td>
          </template>

          <template #offerrev="{item}"
            ><td>{{ item.offerrev.toFixed(2) }}</td></template
          >

          <template #camprev="{item}"
            ><td>{{ item.camprev.toFixed(2) }}</td></template
          >
          <template #status="{item}"
            ><td class="status_td">
              <span v-if="item.status == true">Active</span>
              <span v-if="item.status == false">Inactive</span>
              </td></template
          >
    <!-- <template #offername="{item}"><td>{{ item.offername }}</td></template>

    <template #offerid="{item}"><td>{{ offer_opt[item.offerid]}}</td></template>
    <template #tsid="{item}"><td>{{ ts_opt[item.tsid]}}</td></template> -->
    <template #action="{item}">
        <td class="action_td">
          <span v-if="userType =='csp'" class="link" @click="cSpImageUpload(item._id)">Upload Image</span>
          <div v-else class="row ml-1">
          <span @click="edit(item)" class="p-1">
          <CImg src="img/logo/edit.png" class="ml-1 img-fluid" title="Edit"
                             style="cursor: pointer;"
                             ></CImg>
          </span>
          <span @click="update(item,'hide')" class="p-1">
          <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Delete"
          style="cursor: pointer;"
          ></CImg>
          </span>
          <span  @click="update(item)" class="p-1" >
            <CImg v-if="item.status == true" src="img/logo/push.png" class="img-fluid ml-1" title="Inactive" :pressed.sync="item.status"
          style="cursor: pointer;"
          ></CImg>
            <CImg v-if="item.status == false" src="img/logo/play.png" class="img-fluid ml-1" title="Active" :pressed.sync="item.status"
              style="cursor: pointer;"
          ></CImg>
          </span>
          <!-- <span class="p-1" @click="HitMapData(item)" v-show="iwitness != 'false'">
            <CImg  src="img/logo/heatmap.png" class="img-fluid ml-1" title="HeatMap"  style="cursor: pointer;"
            ></CImg>
          </span> -->
          <span class="p-1" @click="openModel('CampURL', camp_list[item.campname],item.camp_eid)">
            <CImg  src="img/logo/code.png" class="img-fluid ml-1" title="Campaign Url"  style="cursor: pointer;"
            ></CImg>
          </span>
          <span class="p-1" @click="SDKredirect(item)">
            <CImg  src="img/logo/sdkico.png" class="img-fluid ml-1" title="SDK"  style="cursor: pointer;"
            ></CImg>
          </span>
          <span class="p-1" @click="Clone(item._id)">
            <CImg  src="img/logo/clone.png" class="img-fluid ml-1" title="Clone Campaign"  style="cursor: pointer;"
            ></CImg></span>
          <!-- <CButton variant="outline" color="success" :pressed.sync="item.status" @click=update(item)>{{item.status ? 'Active ' : 'Deactive'}}</CButton> -->
          </div>
        </td>
      </template>
      <!--start Custom Filter start -->
      <template #offerid-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="custom_filter.offerid" @keyup="fieldcustomFilter('offerid')">
      </template>
      <template #tsid-filter="{item}">
          <input class="form-control form-control-sm" type="text" v-model="custom_filter.tsid" @keyup="fieldcustomFilter('tsid')">
      </template>
      <template #action-filter="{item}">
         <span>&nbsp;</span>
      </template>
      <template #status-filter="{item}">
         <span>&nbsp;</span>
      </template>
      </CDataTable> </div>
       <!-- Show Model -->
    <CModal
      title="Campaign Url"
      :show.sync="camp_url"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <url-param v-if="userType !='csp'" :url_data='url_data'></url-param>

    <template #footer>
      <CButton @click="camp_url = false" color="danger" class="close_camp_url">Close</CButton>
      <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
    </template>
    </CModal>
    </CCardBody>
    <!-- Campaign Edit Modal  -->
    <CModal
        title="Edit Campaign"
        :show.sync="campaign_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
        
      >
      <campaign-model v-if="userType !='csp'" :edit_campaigndata="edit_campaigndata" :campaign_modal="campaign_modal" @campaignmodal="closeModal"></campaign-model>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>

     <!-- Traffic Source Edit Modal Start  -->
      <CModal
        title="Edit TrafficSource"
        :show.sync="ts_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
        
      >
      <ts-modal v-if="userType !='csp'" :edit_tsdata="edit_tsdata" :ts_modal="ts_modal" @tsmodal="closeModal"></ts-modal>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
      <!-- Traffic Source Edit Modal End  -->
      <!-- Offer Edit Modal Start   -->
      <CModal
        title="Edit Offer"
        :show.sync="offer_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
      >
      <offer-modal v-if="userType !='csp'" :edit_offerdata="edit_offerdata" :offer_modal="offer_modal" @offermodal="closeModal"></offer-modal>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
      <!-- Offer Edit Modal End   -->
      <vue-tour :name="'camplist'"></vue-tour>
    </CCard>
</template>

<script>
import CampaignService from '../../services/campaignControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Multiselect from "vue-multiselect";
import DashboardService from "../../services/dashboardController";
import TsService from "../../services/trafficsourceControllservice";
import OfferService from "../../services/offerControlService";
import Config from "../../services/config";
import UrlParam from "../../components/UrlParam.vue"
import 'sweetalert2/src/sweetalert2.scss'
import CampaignModel from './NewCampaign.vue'
import VueTour from "../../components/vueTour.vue"
import TsModal from '../ts/NewTrafficSource.vue'
import OfferModal from '../offer/NewOffer.vue'
export default {
  name: 'ListCampaign',
  components:{Multiselect, UrlParam,CampaignModel,VueTour, TsModal, OfferModal},
  props:{small: Boolean},
  data() {
        return {
        custom_filter:{},
        campaign_modal:false,
        edit_campaigndata:{},
        iwitness:'false',
        all_data:[],
        filter: '',
        totalRows:0,
        loading:true,
        url: "",
        zeroms_url: "",
        camp_url: false,
        traffic_source_name:[],
        offer_name: [],
        tableperpage:20,
        perPage:[20,50,100],
        edittraffic:false,
        items:[],
        fields: [
        { key: "_id", label: "Campaign ID",sortable: true }, 
        { key: "campname", label: "Campaign",sortable: true },
        { key: "offerid", label: "Offer",sortable: true },
        { key: "tsid", label: "Traffic Source",sortable: true },
        { key: "status", label: "Status",sortable: true },
        { key: "action", label: "Action",sortable: true }
        ],
        sdk_url:'',
        sdk_data:'',
        url_data:{url:'',zeroms_url:'',sdk_data:'',sdk_url:''},
        useremail:'',
        ts_modal:false,
        edit_tsdata:{},
        offer_modal:false,
        edit_offerdata:{},
        editoffer:false,
        userType:""
        }
      },
     async mounted(){
       if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
        else{
        if(localStorage.getItem("camp_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("camp_table"));
        this.iwitness = window.localStorage.getItem("iwitness");
        this.useremail = window.localStorage.getItem("email");
        this.userType = window.localStorage.getItem('user');
        this.getAllData(); 
      }
        
      },
    computed: {
    computedItems () {
      return this.ditems.map(item => {
        return { 
          ...item, 
          // registeredTimestamp: item.registered.timestamp, 
          // registeredDate: item.registered.date 
        }
      })
    },
    dateFilteredItems() {
      return this.computedItems.filter(item => {
        // const date = item.registeredTimestamp
        // return date >= this.startDate && date <= this.endDate
      })
    }
  },
  watch:{
      campaign_modal(value){
        if(value == false){
          this.getAllData();  
        }
      },
      ts_modal(value){
          if(value == false){
          this.getAllData();
          }
        },
      offer_modal(value){
          if(value == false){
          this.getAllData();
          }
        },
      },
  methods: {
    async cSpImageUpload(id){
      let response = await CampaignService.cspuploadImage(id)
      if(response.result){
        let rurl = response.data
        window.open(rurl, '_blank');
        Swal.fire({title: 'Message!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }else{
        Swal.fire({title: 'Message!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
    },
    closeModal(){
      this.campaign_modal = false
      this.ts_modal = false
      this.offer_modal = false
      this.getAllData();  
    },
    decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
},
     customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.campname.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
    fieldcustomFilter(field){
      let search = this.custom_filter[field].toLowerCase();
      let filteredData = [];
      if(search != ''){
        switch(field){
          case 'offerid':
            const offerIds = Object.keys(this.offer_opt).filter(key => this.offer_opt[key].toLowerCase().includes(search));
            filteredData = this.all_data.filter(item => offerIds.some(offerId => item.offerid.includes(offerId)));
            this.items = filteredData;
            break;
          case 'tsid':
            const tsIds = Object.keys(this.ts_opt).filter(key => this.ts_opt[key].toLowerCase().includes(search));
            filteredData = this.all_data.filter(item => tsIds.some(tsId => item.tsid.includes(tsId)));
            this.items = filteredData;
            break;
        }
        
      }else{
        this.items = this.all_data;
      }
    },
    async getofferData(id) {
      try {
        var data = { offerid: id };
        let response = await OfferService.get(data);
        this.$root.$data.offer = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/offer/newoffer");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    openModel(txt, id, omsid) {
      switch (txt) {
        case "CampURL":
          this.url = Config.campurl + id;
          this.zeroms_url = Config.zeromsurl + omsid;
          this.sdk_url = Config.sdkurl + id;
          var sdk_data = "&lt;script src=&quot;"+this.sdk_url+"&quot; defer&gt;&lt;/script&gt;\n"+
                          "&lt;script&gt;\n"+
                            "document.addEventListener(&quot;rendered&quot;, function (e) {\n"+
                                "var event_id=e.detail;\n"+    
                            "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n"+
                            "})&lt;/script&gt;";
          this.sdk_data = this.decodeHTMLEntities(sdk_data)
          this.url_data.url = this.url
          this.url_data.zeroms_url = this.zeroms_url
          this.url_data.sdk_data = this.sdk_data
          this.url_data.sdk_url = this.sdk_url
          this.camp_url = true;
          break;
      }
    },
    checkPage(){
      localStorage.setItem("camp_table", this.tableperpage);
    },
    async getAllData(){
      try {
        let response = await CampaignService.get()
        //   this.users = response.data.message;
        this.items = response.message.campaigns;
        this.all_data = response.message.campaigns;
        this.offer_opt = response.message.offer_opt
        this.ts_opt = response.message.traffic_opt
        this.totalRows = this.items.length;
        this.loading = false;
        this.getCamplist();
    } catch (e) {
      // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
      // this.errorNotify("Error", "connection error", "danger");
      //   this.$router.push("Login");
    }
      if(window.localStorage.getItem('guide_tool') == 'true'){
        this.openModel('CampURL', this.camp_list[this.items[0].campname],this.items[0].camp_eid)
        this.$tours['camplist'].start()
        var classes = ['.create-offer','.traffic-source','.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
      }
    },
    openDiv(txt,id) {
      switch(txt){
        case "CampURL":
          this.url = Config.campurl+id;
          this.camp_url = true; break;
      }
    },
   async Clone(id){
      let response = await CampaignService.clone({ campid: id })   
      Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      this.getAllData();
    },
    offerEvent(txt){
        if(txt){
          Swal.fire({title: 'Success!',text: "Data updated successfully",icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          // this.errorNotify("Success", "data updated successfully", "success");
         this.$refs['my-modal'].hide()
         this.getAllData();
        }
        else{
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          // this.errorNotify("Error", "connection error", "danger");
          this.$refs['my-modal'].hide()
        }
     },
    async edit(data) {
      // this.$root.$data.campaign = data;
      // this.$root.$data.edit = true;
      this.edit_campaigndata = {};
      this.campaign_modal = false;
      this.campaign_modal = true;
      this.edit_campaigndata = data;
      this.editcampaign = true;
      // this.$router.push('/campaign/newcampaign');
    },
    // HitMapData(data){
    //   var heatmapdata = {_id:data._id,name:data.campname}
    //   this.$root.$data.heatmapdata = heatmapdata;
    //   this.$router.push('/campaign/hitmap');
    // },
    SDKredirect(data){
      let r = {_id:data._id,campname:data.campname}
      this.$root.$data.campdata = r;
      this.$router.push('/campaign/generatesdk');
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    logout() {
      swal("Are you sure you want to Logout?", {
        buttons: ["No!", "Yes!"]
      }).then(value => {
        if (value == true) {
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          this.$router.push("Login");
        }
      });
    },
    async update(formData, txt) {
      
      try {
        if (txt == "hide") {
          if(confirm("Are you sure you want to delete?")){
          let response = await CampaignService.delete({ campid: formData._id});
          if(response.result){  
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          this.getAllData();
          }
         } else {
           var status = true
            if(formData.status == true){
              status = false
            }
            else{
              status == true
            }
          formData.status = status
        let id = formData._id;
        let updated_data = {
          campid: id,
          changePayload: formData
        };
        let response = await CampaignService.update(updated_data)
        
        if (response.result == true) {
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        } else {
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        }}
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      if (formData.isactive == false) {
          formData.isactive = true;
        } else {
          formData.isactive = false;
        }
      }
    },
    async getCamplist(){
      let response = await DashboardService.camplist();
      if (response.result){
        this.camp_list = response.message.campaign;
        this.offer_name = response.message.offer;
        this.traffic_source_name = response.message.trafficsource;
        }
    },
    async EditTrafficSource(data){
      this.edit_tsdata = {};
      var data = { useremail: this.useremail, tsid: data.tsid };
        let response = await TsService.tsget(data);
        if(response.result){
          this.ts_modal = false;
          this.edit_tsdata = response.message[0];
          this.ts_modal = true;
          this.edittraffic = true;
        }
    },
    async EditOffer(data){
      this.edit_offerdata = {}
      var data = { useremail: this.useremail, offerid: data.offerid };
      let response = await OfferService.get(data);
      if(response.result){
        this.offer_modal = false;
        this.edit_offerdata = response.message[0];
        this.offer_modal = true;
        this.editoffer = true;
        }
    }
  }

}
</script>

<style scoped>
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.status_td{
   width: 3% !important;
}
/* .camp_td{
  width: 5% !important;
} */
.offer_td{
  width: 15% !important;
}
.ts_td{
  width: 15% !important;
}
.action_td{
  width: 25%;
}
</style>
<style>
#campurlModal{
  z-index: 10000 !important;
}</style>